.profiel {
  background-color: var(--blue);
  height: 100vh;
  border-left: 1px solid var(--white);
  padding-left: 1.5rem;
  padding-top: 4rem;
  transform: translateX(0vw);
  transition: transform 1s ease-in-out;
  position: relative;
  text-align: center;

  & img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto 1rem auto;
    display: block;
  }

  & h1 {
    font-size: 2rem;
  }

  & a {
    color: var(--white);
  }
}

.profile-panel {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background-color: var(--white);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 100;
  transition: right 0.2s;
}

.profile-panel.open {
  right: 0;
}

.badges {
  margin-top: 5rem;
}

.links {
  display: flex;
  margin-top: 12rem;

  & a {
    text-decoration: none;
  }
}