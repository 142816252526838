.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.popup-content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 24px;
  border-radius: 5px;

  width: 100%;
  max-width: 500px;
  margin: 0 16px;
}

.button {
  padding: 5px 16px;
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 0.2rem;
  text-transform: uppercase;
}

.button-blue {
  background-color: var(--dark-yellow);
}

.button-blue:hover {
  background-color: var(--yellow);
}

.button-red {
  background-color: var(--dark-blue);
}

.button-red:hover {
  background-color: var(--blue);
}

.text-black {
  color: var(--black);
}