.uitgelichte-missies_flex {
  .individual-mission {
    border: 2px solid var(--white);
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .individual-mission p {
    transform: rotate(-45deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .outer-border {
    border: 1px solid var(--yellow);
    padding: 0.2rem;
    width: 50px;
    height: 50px;
  }

  .rotated {
    transform: rotate(45deg);
  }

  &.rotated:hover>.outer-border {
    border: 3px solid var(--yellow);
    padding: .1rem;
    box-shadow: 0px 0px 4px 1px var(--light-yellow);
  }
}