input,
label {
    display: block;
}

label {
    color: var(--white);
}

input {
    background-color: var(--dark-blue);
    color: var(--white)
}

.outer-settingsButton {
    border: 1px solid var(--white);
    padding: 0.375rem;
    display: inline-block;
    margin-left: 1rem;
    margin-top: 1rem;
}