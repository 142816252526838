/* footer stuff */
footer {
  position: absolute;
  bottom: 0;
  background: var(--gray);
  width: 100%;
}

footer div {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

footer ul {
  padding-bottom: .5rem;
  display: flex;
}

footer .disclaimer {
  color: var(--white);
  font-family: Nokora;
  font-weight: 100;
  font-size: 10px;
  font-style: italic;
}