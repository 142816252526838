.settings {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 95%;
  width: 75%;

  & img {
    width: 200px;
  }

  & form {
    width: 100%;
  }

  .general-settings {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .slider {
    display: flex;
    justify-content: space-between;
    margin: 1.2rem 0 1.2rem 0;
    padding: 0 .5rem 0 .5rem;

    & p {
      text-transform: uppercase;
    }
  }

  .sliders {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 8rem;
    box-shadow: 0px 0px 4px 1px var(--light-yellow);
    border: 2px solid var(--yellow);

    text-transform: uppercase;

    & button {
      width: 50%;
      border: none;
      background: none;
      color: var(--white);
      text-transform: uppercase;
    }

    & .active {
      box-shadow: 0px 0px 4px 1px var(--light-yellow);
      background: var(--yellow);
    }

    & .active:hover {
      background: var(--light-yellow);
    }

    & :hover {
      background-color: var(--blue);
    }
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  a {
    /* margin-top: 2rem; */
    font-size: 10px;
  }
}

.settingsButton {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--white);
  color: var(--white);
  padding: 0;
  /* Remove padding from button itself */
  display: block;

  & a {
    margin-top: 0;
  }
}